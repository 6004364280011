<template>
  <v-row justify="center">
    <v-dialog v-model="show" max-width="500" persistent>
        <v-card class="pa-6">
          <v-card-text class="text-center pa-0 pb-6 text-h6 font-weight-regular">
            ระบบอยู่ระหว่างการปรับปรุง ขออภัยในความไม่สะดวก
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>
            <v-btn outlined @click="$emit('close')" color="red">Close</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

export default {
  data: function() {
    return {      
    };
  },
  computed: {
  },
  props: ["show"],
  methods: {   
  },
};
</script>
<style></style>
